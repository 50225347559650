.Balance {
  width: 100%;
  position: relative;
}

.Balance--withSubValue {
  padding: 17px 0;
}

.Balance__title {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 12px;
  background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Balance__value {
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  color: #FFFFFF;
}

.Balance__subValue {
  padding-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7A7A7A;
}

.Balance__content {
  background: #313131;
  padding: 24px 0;
}

@media only screen and (max-width: 600px) {
  .Balance__content {
    padding: 16px;
  }
}
@import "src/ui-kit/styles/vars";

.header {
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    color: white;
    z-index: 6;
    background: $black;

    border-bottom: 1px solid $dark;

    &__section {
        display: flex;
        align-items: center;
    }

    &__rewards {
        padding: 6px 14px;
        background: #313131;
        border-radius: 40px;
        color: #FFFFFF;

        font-size: 12px;
        line-height: 16px;
        margin-right: 20px;

        @media only screen and (max-width: $mobile-width) {
            padding: 5px 8px;
            margin: 0 12px;
        }
    }

    &__connect {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        padding: 8px 16px;
        cursor: pointer;
    }

    &__address-button {
        height: 40px;
        padding: 8px 10px 8px 8px;

        &__title {
            padding: 0 8px;
        }
    }

    &__menu {
        margin-left: 16px;
    }

    @media only screen and (max-width: $mobile-width) {
        padding: 14px 16px;
    }
}
